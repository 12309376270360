
import logo from '../img/j-logo.png';
import { IVSAddRemoveProps } from '../body/type';

interface IProps extends IVSAddRemoveProps {
  terminalVisibility: ()=>void
}

const Header = (props: IProps) => {
  const { values, handleAddHeaderTab, terminalVisibility } = props;
  const menus = values.map((val)=><span onClick={()=>handleAddHeaderTab(val.id)} key={val.id}>{val.menu}</span>)
  return (
       <div className="container pl-1.5 mx-auto flex flex-row gap-3.5 mt-2 pb-2 navigation">
          <div className="p-0.5">
            <img src={logo}  alt="Jeoffy Hipolito" />
          </div>
          <div className="p-0.5 basis-1/2 flex flex-row space-x-6 menus">
             {menus}
             <span onClick={terminalVisibility}>Terminal</span>
          </div>
        </div>
  );
}

export default Header;


import {  ICodeBlockProps } from '../body/type';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faHandPointRight, faLink, faVideo } from '@fortawesome/free-solid-svg-icons';


const CodeBlock = (props: ICodeBlockProps) => {

    const { values, removeKeys } = props;

 const displayCode = values.map((val, key)=>  {

    const codeStyle = {
        left: val?.left ? val?.left : '',
        color: val?.color ? val?.color : '',
        textDecoration: val?.url ? 'underline' : '' 
    } 

    return (
        <li className='full-width-list' key={key}>
                {!removeKeys && <div className='p-1   text-center num-line'>{key}</div>}
                <div className={`p-1 ${!removeKeys ? 'code-line': ''} ${val.paddingLeft}  text-sky-700`}>
                    {
                        (val.url || val.github || val.video) ? (
                            <>
                            { 
                                val.url && <Link to={val.url} target='_blank'><span style={codeStyle}>{val.value} <FontAwesomeIcon icon={faLink} /></span> </Link>
                            }
                            { 
                                val.github && <Link to={val.github} target='_blank'><span style={codeStyle}> <FontAwesomeIcon icon={faCircleDot} /> GitHub <FontAwesomeIcon icon={faHandPointRight} /></span> </Link>
                            }
                            { 
                                val.video && <Link to={val.video} target='_blank'><span style={codeStyle}> <FontAwesomeIcon icon={faCircleDot} /> Demo <FontAwesomeIcon icon={faVideo} /></span> </Link>
                            }
                            </>
                        ) : (
                            <span style={codeStyle}>{val.value}</span>
                        )
                    }
                    
                </div> 
            </li>
     )
    }
 )


  return (
    <ul className='full-width-ul'>
         {displayCode}
    </ul>
  );
}

export default CodeBlock;

import { FC, ReactNode } from 'react';


type Props ={
    children: ReactNode 
}
const BodyWrapper:FC<Props> = ({ children }) => {
  return  (
    <div className="container mx-auto flex flex-row minHeight">
          {children}
    </div>
  )
}

export default BodyWrapper;


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="pr-2 icons">
        <ul>
            <li className='tooltip'>
              <Link to="https://www.linkedin.com/in/jeoffy-hipolito-021992128/" target='_blank'><span className='icon-border'>in</span></Link>
              <span className="tooltiptext">LinkedIn</span>
            </li>
            <li className='tooltip'>
              <Link to="https://github.com/jeoffydev" target='_blank'><span><FontAwesomeIcon icon={faCodeBranch} /></span></Link>
              <span className="tooltiptext">GitHub</span>
            </li>
        </ul>
    </div>
  );
}

export default Sidebar;

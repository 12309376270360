import { faCodeBranch, faEnvelope, faHandPointRight, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom'

const WelcomePage = () => {
  return (
        <div className='grid grid-cols-2 p-2 vertical-box text-gray-300'>
            <div className='pl-8'>
                <p className='text-2xl font-bold '>Hey, I'm Jeoffy.</p>
                <p className='text-slate-400'> I&apos;m a Front-End Developer and passionate about programming and software development.</p>
                <p className='text-slate-400'>I work with leading-edge companies to develop, simple and code exceptional user experiences. </p>
                <p className='font-serif italic mt-4 text-xl  font-bold box-decoration-slice bg-gradient-to-r from-indigo-600 to-pink-500 text-white px-2'>I bring fire-power and ignite the design to life! </p>
                <div className='p-4 bg-zinc-700 mt-4 rounded-md'>
                    <p><FontAwesomeIcon icon={faCodeBranch} /> GitHub</p>
                    <p><Link to="https://github.com/jeoffydev" target='_blank'>Checkout my Repositories here <FontAwesomeIcon icon={faHandPointRight} /> <FontAwesomeIcon icon={faLink} /></Link></p>
                    <p className='mt-2'><FontAwesomeIcon icon={faEnvelope} /> Shoot me an email!</p>
                    <p> <Link  to="mailto:jeoffy_hipolito@yahoo.com"> jeoffy_hipolito@yahoo.com</Link></p>
                </div>
                

            </div>
            <div className='pl-8'>
                <p className='text-2xl font-bold '>Recommended</p>
                <div className='p-4 bg-zinc-700 mt-4 rounded-md '>
                     <blockquote className='font-serif italic'>&quot; I personally recommend Jeoffy very highly and would hire him again if the opportunity arose&quot;</blockquote>
                     <p className='mt-2'><span className='font-bold'>Leon Fromont</span> <br />Head of Engineering of <Link to="https://www.tabula.live/" target='_blank' className='text-cyan-400'>Tabula</Link> </p>
                </div>
                
            </div>
            
           
        </div>
  );
}

export default WelcomePage;


import { ICodeBlock, ITabHeaders } from "./type";

export const pagesContent: ITabHeaders[] = [
    {
        id: 1,
        name: 'Welcome',
        menu: '',
        active: true,
    },
    {
        id: 2,
        name: 'Projects.html',
        menu: 'Projects',
        active: true,
    },
    {
        id: 3,
        name: 'Resume.tsx',
        menu: 'Resume',
        active: true,
    },
    {
        id: 4,
        name: 'contact.scss',
        menu: 'Contact',
        active: true,
    },
]

export const projectContent: ICodeBlock[] = [
    {
        value: '<!DOCTYPE html>'
    },
    {
        value: '<html lang="en">'
    },
    {
        value: '<head>',
        left: '1rem'
    },
    {
        value: '<title>',
        left: '2rem'
    },
    {
        value: 'Selected Projects',
        color: 'yellow',
        left: '3rem'
    },
    {
        value: '</title>',
        left: '2rem'
    },
    {
        value: '</head>',
        left: '1rem'
    },
    {
        value: '<body>',
    },
    {
        value: '<section>',
        left: '2rem'
    },
    {
        value: '<ul>',
        left: '3rem'
    },
    {
        value: '<li>',
        left: '4rem'
    },
    {
        value: 'Building Facility Management System',
        color: '#71E9F2',
        left: '5rem',
        url: 'https://proud-water-06afbfe10.3.azurestaticapps.net/',
        github: 'https://github.com/jeoffydev/asp-bpm-FE-new',
    },
   
    {
        value: '/** ',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '* Facility Job and Maintenance Management System for BEAMS',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '* ReactJs, TypeScript, Redux-Toolkit, Styled-components, MUI, Lodash, Jest, Cypress, MomentJs, React Hook Form, Tailwind CSS, Webpack, HTML, CSS, Sass, SCSS',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    
    {
        value: '* Back-End: ASP.Net Core Web API, XUnit (Unit test framework), SQL ',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: ' */',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: 'End-to-end Cypress test for user login',
        color: 'rgb(163 163 163)',
        left: '5rem',
        url: 'https://share.vidyard.com/watch/8frRcYcPi3M5G4uLJ6eoZF?',
        video: 'https://share.vidyard.com/watch/8frRcYcPi3M5G4uLJ6eoZF?'
    },
    {
        value: '</li>',
        left: '4rem'
    },
    {
        value: '<li>',
        left: '4rem'
    },
    {
        value: 'THL Digital (SaaS) ',
        color: '#71E9F2',
        left: '5rem',
        url: 'https://motek.thlonline.com',
    },
    {
        value: 'Selected Components',
        color: '#71E9F2',
        left: '5rem',
        url: 'https://share.vidyard.com/watch/RTJbWphAuVj2rbRijaKD4S', 
        github: 'https://share.vidyard.com/watch/PHyqgCDb2KXSRfiNURhF5A',
    },
    {
        value: 'Other Demos',
        color: '#71E9F2',
        left: '5rem',
        url: 'https://share.vidyard.com/watch/o1qBmZ3ixPVTne75tQKLjs', 
    },
    {
        value: '/** ',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '* World`s largest provider of global RV experiences across Australia, New Zealand, USA and UK.',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '* React,  Redux ToolKit,  Redux Form, ES6/ES7, Webpack/Grunt/Babel, React-Router,    Style-Components, Storybooks, React-intl, ',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    }, 
    {
        value: '*  Axios, RESTful API, Jest, NodeJs, MongoDb, Webpack, HTML, CSS, Sass, SCSS, Figma',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    }, 
    {
        value: ' */',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: 'MAUI Motorhomes Rental',
        color: 'rgb(163 163 163)',
        left: '5rem',
        url: 'https://www.maui-rentals.com/nz/en',
    },
    {
        value: 'Britz Motorhomes Rental',
        color: 'rgb(163 163 163)',
        left: '5rem',
        url: 'https://www.britz.com/nz/en',
    },
    {
        value: 'Mighty Campers  Rental',
        color: 'rgb(163 163 163)',
        left: '5rem',
        url: 'https://www.mightycampers.com/nz/en',
    },
    {
        value: '/** ',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '* Self-checkin websites are built of NextJs, ReactJs, TypeScript,  Styled-components, Webpack, HTML, CSS, Sass, SCSS, NodeJs',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    }, 
    {
        value: ' */',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '</li>',
        left: '4rem'
    },
    {
        value: '<li>',
        left: '4rem'
    },
    {
        value: 'Tabula formerly TracMap GPS guided proof of application and job management system',
        color: '#71E9F2',
        left: '5rem',
        url: 'https://app.tabula-online.com/login?next=%2F',
        video: 'https://share.vidyard.com/watch/hnKNc9QXL2TaYkguqsqe2x'
    },
    {
        value: 'Demo',
        color: '#71E9F2',
        left: '5rem',
        video: 'https://share.vidyard.com/watch/iNRthFuDBJ3WWxzpETW9NZ'
    },
   
    {
        value: '/** ',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '* GPS guided proof of application and job management system',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '* ReactJs, TypeScript, React Mapbox GL, JoTai, React-Table, MUI, Redux, TurfJs, ImmerJs, Lodash, Styled-components,  Webpack, ',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    }, 
    {
        value: '*   HTML, CSS, Sass, SCSS, Jest,  ExpressJs, NodeJs',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    }, 
    {
        value: ' */',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: 'TracMap Engineering',
        color: 'rgb(163 163 163)',
        left: '5rem',
        url: 'https://engineering.tracmap.com/careers/',
    },
    
    {
        value: '/** ',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '* Single Page Application for careers page in TracMap',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '* NextJs, ReactJs, TypeScript,  Styled-components,  React Bootstrap Framework, NodeJs',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    }, 
    {
        value: ' */',
        left: '4.5rem',
        color: 'rgb(5 150 105)'
    },
    {
        value: '</li>',
        left: '4rem'
    },

    {
        value: '</ul>',
        left: '3rem'
    },
    {
        value: '</section>',
        left: '2rem'
    },
    {
        value: '</body>',
    },
]

export const resumeContent: ICodeBlock[] = [
    {
        value: `import React, { useEffect, useState } from 'react';`,
    },
    {
        value: `import { useDispatch, useSelector } from 'react-redux';`,
    },
     
    {
        value: `const Resume =<IProps>({ name, qualification }) => { `,
         
    },
    {
        value: `const [skills, setMySkills] = useState([`,
        
        left: '1rem'
    },
    {
        value: `'ReactJs', 'TypeScript', 'Redux ToolKit', 'JavaScript', 'MUI', 'KeystoneJs', 'Restful API', 'Webpack', 'HTML', 'CSS', 'Sass', 'SCSS', 'Tailwind CSS', `,
        color: 'rgb(180 83 9)',
        left: '2rem'
    },  
    {
        value: ` 'ASP.Net Core Web API and MVC', 'SQL', 'Entity Framework', 'XUnit'`,
        color: 'rgb(180 83 9)',
        left: '2rem'
    },  
    {
        value: `]); `,
        
        left: '1rem'
    },
    {
        value: `return ( `,
        
        left: '1rem'
    },
    {
        value: `<ResumeComponent>`,
        
        left: '1.5rem'
    },
    {
        value: `<My name='Jeoffy Hipolito' />`,
        color: 'white',
        left: '2.5rem'
    }, 
    {
        value: `<My qualification='Bachelor of Science in Computer Engineering ' />`,
        color: 'rgb(94 234 212)',
        left: '2.5rem'
    }, 
    {
        value: `<My qualification='Software Engineering Institute of Data (AUT)' />`,
        color: 'rgb(94 234 212)',
        left: '2.5rem'
    }, 
    {
        value: `<ProfessionalExperience>`,
       
        left: '2.5rem'
    },
    {
        value: `<Company name='THL Digital (SaaS)'>`,
        color: 'rgb(253 224 71)',
        left: '3.5rem'
    }, 
    {
        value: `<Position title='Front-End Developer' />`,
        color: 'rgb(132 204 22)',
        left: '4.5rem'
    }, 
    {
        value: `</Company>`,
        color: 'rgb(253 224 71)',
        left: '3.5rem'
    }, 
    {
        value: `<Company name='Tabula formerly TracMap (SaaS)' />`,
        color: 'rgb(253 224 71)',
        left: '3.5rem'
    }, 
    {
        value: `<Position title='Front-End Web Developer' />`,
        color: 'rgb(132 204 22)',
        left: '4.5rem'
    }, 
    {
        value: `</Company>`,
        color: 'rgb(253 224 71)',
        left: '3.5rem'
    }, 
    {
        value: `<Company name='Tuapeka Gold Print'>`,
        color: 'rgb(253 224 71)',
        left: '3.5rem'
    }, 
    {
        value: `<Position title='Full Stack Web Developer' />`,
        color: 'rgb(132 204 22)',
        left: '4.5rem'
    }, 
    {
        value: `</Company>`,
        color: 'rgb(253 224 71)',
        left: '3.5rem'
    },
    {
        value: `<Company name='KlerqIVS'>`,
        color: 'rgb(253 224 71)',
        left: '3.5rem'
    }, 
    {
        value: `<Position title='PHP Developer' />`,
        color: 'rgb(132 204 22)',
        left: '4.5rem'
    }, 
    {
        value: `</Company>`,
        color: 'rgb(253 224 71)',
        left: '3.5rem'
    },
    {
        value: `<Company name='AJH Creative & Design'>`,
        color: 'rgb(253 224 71)',
        left: '3.5rem'
    }, 
    {
        value: `<Position title='Full Stack Web Developer' />`,
        color: 'rgb(132 204 22)',
        left: '4.5rem'
    }, 
    {
        value: `</Company>`,
        color: 'rgb(253 224 71)',
        left: '3.5rem'
    },
    {
        value: `</ProfessionalExperience>`,
        
        left: '2.5rem'
    },
    {
        value: `</ResumeComponent>`,
         
        left: '1.5rem'
    },
    {
        value: `) `,
        
        left: '1rem'
    },
    {
        value: `} `,
        color: 'rgb(8 145 178)'
    },
    {
        value: `export default Resume`, 
    },
     
]

export const terminalValues = [

    {
        value: '$ yarn start',
        color: 'white'
    },
    {
        value: 'yarn run v1.44.33',
        color: 'white'
    },
    {
        value: '$ react-scripts start',
        color: 'grey'
    },
    {
        value: 'Starting the development server...',
        color: 'white'
    },
    {
        value: 'You can now view Jeoffy`s portfolio in the browser',
        color: 'yellow'
    },
    {
        value: 'Fake webpack compiled successfully',
        color: 'white'
    },
    {
        value: 'Ooops... I tricked you :p',
        color: 'green'
    },
    {
        value: 'Finally! No issues found.',
    },
    {
        value: 'Jeoffy`s modern tech stack and skills:',
        color: 'yellow'
    },
    {
        value: 'ReactJs, TypeScript, Redux Toolkit, React Query, Material UI/MUI....'
    },
    {
        value: 'CSS, Tailwind CSS, SCSS, SASS, HTML5...'
    },
    {
        value: 'Jest, Cypress, NextJs, AngularJs, NodeJs, Bootsrap Framework, GraphQL, Figma, Adobe Creative Suite...'
    },
    {
        value: 'ASP.Net Core WEB API and MVC, SQL, Entity Framework, PHP, MySQL '
    },
]


export const contactContent: ICodeBlock[] = [
    {
        value: `$Email_Address: jeoffy_hipolito@yahoo.com;`,
        color: 'rgb(231 229 228)'
    }, 
    {
        value: `$GitHub: github.com/jeoffydev;`,
        color: 'rgb(234 88 12)'
    },
    {
        value: `$LinkedIn: in/jeoffy-hipolito-021992128;`,
        color: 'rgb(103 232 249)'
    }, 
    {
        value: `.Let_us_talk { `,
        color: 'rgb(234 179 8)'
    }, 
    {
        value: `& .send_me_an_email {  `,
        color: 'rgb(234 179 8)',
        left: '2rem'
    }, 
    {
        value: `$Email_Address`,
        color: 'rgb(231 229 228)',
        left: '4rem'
    }, 
    {
        value: `} `,
        color: 'rgb(234 179 8)',
        left: '2rem'
    },
    {
        value: `& .code_I_wrote {  `,
        color: 'rgb(234 179 8)',
        left: '2rem'
    }, 
    {
        value: `$GitHub`,
        color: 'rgb(234 88 12)',
        left: '4rem'
    }, 
    {
        value: `} `,
        color: 'rgb(234 179 8)',
        left: '2rem'
    },
    {
        value: `& .see_my_profile {  `,
        color: 'rgb(234 179 8)',
        left: '2rem'
    }, 
    {
        value: `$LinkedIn`,
        color: 'rgb(103 232 249)',
        left: '4rem'
    }, 
    {
        value: `} `,
        color: 'rgb(234 179 8)',
        left: '2rem'
    },
    {
        value: `} `,
        color: 'rgb(234 179 8)'
    }, 
    {
        value: '/** ',
        color: 'rgb(5 150 105)'
    },
    {
        value: '* Keep in touch and I would love to hear from you',
        color: 'rgb(5 150 105)'
    }, 
    {
        value: ' */',
        color: 'rgb(5 150 105)'
    },
]

import { ITabHeaders } from './type';
import { contactContent,  projectContent, resumeContent } from './constants';
import CodeBlock from '../pages/CodeBlock';
import WelcomePage from '../pages/WelcomePage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    activePage: ITabHeaders,
    terminal: boolean
}

const Content = (props: IProps) => {

    const {  activePage, terminal } = props;

    return (
            <>
                { activePage?.id && <div className='box-shadow shadow-lg mb-0'><span>src <FontAwesomeIcon icon={faChevronRight} /></span> <span>{activePage?.name}</span></div> }
                
                <div className={`elem-scroll flex    ${(activePage?.id === 1 && terminal) ? ' items-center ' : ''} ${terminal  ? 'h-72 overflow-auto' : ''}`}>
                    {
                        activePage?.id === 1 && <WelcomePage />
                    }
                    {
                        activePage?.id === 2 && <CodeBlock values={projectContent} />
                    }
                    {
                        activePage?.id === 3 && <CodeBlock values={resumeContent} />
                    }
                     {
                        activePage?.id === 4 && <CodeBlock values={contactContent} />
                    }
                </div>
                   
            </>
    );
}

export default Content;

import { useState } from 'react';
import './App.scss';
import Header from './header/Header';
import BodyWrapper from './body/BodyWrapper';
import Sidebar from './sidebar/Sidebar';
import MainBody from './body/MainBody';
import { pagesContent } from './body/constants';
import { ITabHeaders } from './body/type';


function App() {
 

  const [ pages, ] = useState<ITabHeaders[]>(pagesContent.filter((v)=>v.id !== 1));
  const [ tabHeaders, setTabHeaders ] = useState<ITabHeaders[]>(pagesContent.filter((v)=>v.id === 1));
  const [ terminal, setTerminal ] = useState<boolean>(true);


  const handleRemoveHeaderTab = (id: number) => {
    const filterHeaders = tabHeaders.filter((tID)=> tID.id !== id)
    const getTheLast = filterHeaders.slice(-1)?.map((val)=>( 
      {
        ...val,
        active: true 
      }
    ));
    if ( getTheLast.length > 0 ) {
      const removeFirst = filterHeaders.filter((val)=>val.id !== getTheLast[0].id);
      const finalResult = [
        ...removeFirst,
        ...getTheLast
      ]
      setTabHeaders(finalResult);
    } else {
      setTabHeaders(filterHeaders)
    }
    
 }

 const handleAddHeaderTab =  (id: number) => {
  const checkIfIDExist = Boolean(tabHeaders.find((val)=>val.id === id));
  if ( !checkIfIDExist ) {
    const editTabHeaders = tabHeaders.map((val)=>({
        ...val,
        active: false
      }
    ))
    const addTab =   pages.filter((val)=>val.id === id);
     const addPages = [
       ...editTabHeaders,
       ...addTab
     ]
     setTabHeaders(addPages)
  } else {
      const activateThisHeader = tabHeaders.map((val)=>(
        {
          ...val,
          active: val.id === id ? true : false
        }
      ))
      setTabHeaders(activateThisHeader)
  }
}

const terminalVisibility = () => setTerminal((t)=>!t)

  return (
    <div className="App text-sm">
        <Header  
          values={pages} 
          handleAddHeaderTab={handleAddHeaderTab} 
          handleRemoveHeaderTab={handleRemoveHeaderTab} 
          terminalVisibility={terminalVisibility}
        />
        <BodyWrapper>
          <Sidebar />
          <MainBody 
            terminal={terminal}
            values={tabHeaders} 
            handleRemoveHeaderTab={handleRemoveHeaderTab} 
            handleAddHeaderTab={handleAddHeaderTab}
            terminalVisibility={terminalVisibility}
            
          />
        </BodyWrapper>

    </div>
  );
}

export default App;

import { useCallback, useEffect, useState }  from 'react';
import { IVSAddRemoveProps,   } from './type';
import {  terminalValues } from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import CodeBlock from '../pages/CodeBlock';

interface IProps extends IVSAddRemoveProps {
    terminal: boolean;
    terminalVisibility: ()=>void
}

const Terminal = (props: IProps) => {

    const {  terminal, terminalVisibility } = props;
    const [addTimer, setAddTimer] = useState(0);

    const delay =useCallback(() => {
        setTimeout(function() { 
        setAddTimer((d)=>d + 1) 
        }, 4000)
    },
    []);

    useEffect(()=>{
          if ( addTimer < terminalValues.length ) {
            delay()
          }
        },[
          addTimer,
          delay
    ])

    const displayTerminals =() => {

        const terminalFilter = terminalValues.filter((item, ind)=> ind < addTimer && (
            {
                value: item.value
            }
        ))
         
        return <CodeBlock values={terminalFilter} removeKeys={true}/>
    }
   
    return (
                 
           <>
                {
                            terminal && (
                                <div className='floating-terminal'>
                                    <div className='box-shadow shadow-lg mb-2'>
                                        <span className='terminal-btn'>Terminal</span> 
                                        <span className='close-btn' onClick={terminalVisibility}> <FontAwesomeIcon icon={faXmark} /></span>
                                    </div>
                                    <div className='p-4 text-xs'>
                                    <p><span className='text-green-500'>Jeoffy@FE-Developer</span><span className='text-yellow-500'>~/portfolio</span> <span className='text-cyan-400'>(master)</span></p>
                                    <ul>
                                        {displayTerminals()}
                                        <li>...<span className='blink'></span> </li>
                                    </ul>
                                    </div>
                                </div>
                            )
                }
            </>            
                       
                   
    );
}

export default Terminal;

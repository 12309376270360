
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import {  IVSAddRemoveProps,  } from '../body/type';
import htmImg from '../img/html.jpg';
import reactImg from '../img/react.jpg';
import scssImg from '../img/scss.png';


const TabHeader = (props: IVSAddRemoveProps) => {
     const { values, handleRemoveHeaderTab, handleAddHeaderTab } = props;
     
     
     const listTabHeaders = values.map((val)=> {
               let img = '';
               if( val.id === 2 ) {
                    img = htmImg;
               }  else if (val.id === 3) {
                    img = reactImg;
               } else if ( val.id === 4) {
                    img = scssImg;
               }
               const iconStyle = {
                    backgroundImage: `url(${img})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPositionY: 'center',
                    backgroundPositionX: '2%'
               };     
               return <li style={iconStyle}  className={`tab-header p-1 ${!val.active && 'text-slate-500 italic'}`} key={val.id}><span onClick={()=>handleAddHeaderTab(val.id)} >{val.name}</span><span className='close-icon' onClick={()=>handleRemoveHeaderTab(val.id)} ><FontAwesomeIcon icon={faXmark} /></span></li>
          }
     )

     return (
          <ul className='tabUl'>
                {listTabHeaders}
          </ul>
     );
}

export default TabHeader;

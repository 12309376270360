
import TabHeader from '../tabs/TabHeader';
import { ITabHeaders, IVSAddRemoveProps,   } from './type';
import Content from './Content';
import Terminal from './Terminal';

interface IProps extends IVSAddRemoveProps {
    terminal: boolean;
    terminalVisibility: ()=>void
}

const MainBody = (props: IProps) => {

    const {handleRemoveHeaderTab, values, handleAddHeaderTab, terminal, terminalVisibility } = props;
    const activePage = values.find((val)=> val.active && <div key={val.id}>{val.name}</div>)

    return (
            <div className="pr-1 pb-1 pt-0 pl-0 middle-page">
                    <div>
                        <TabHeader 
                            handleAddHeaderTab={handleAddHeaderTab} 
                            values={values} 
                            handleRemoveHeaderTab={handleRemoveHeaderTab} 
                        />
                    </div>
                    <div className='body-code grid grid-cols-1'>
                        <Content 
                            activePage={activePage as ITabHeaders}
                            terminal={terminal}
                        />
                        {
                            terminal && (
                                <Terminal 
                                    terminal={terminal}
                                    terminalVisibility={terminalVisibility}
                                    handleAddHeaderTab={handleAddHeaderTab} 
                                    values={values} 
                                    handleRemoveHeaderTab={handleRemoveHeaderTab} 
                                />
                            )
                        }
                       
                    </div>
                    
            </div>
    );
}

export default MainBody;
